


































































import Table from "@monorepo/reports/src/views/PassportArchiveStoreReportView/components/Table.vue";
import TableHeader from "@monorepo/uikit/src/components/tableViews/TableHeader.vue";
import Filters from "@monorepo/uikit/src/components/common/Filters.vue";
import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import { viewTitle, viewUniqKey } from "@monorepo/utils/src/variables/projectsData/passportArchiveStoreReport/viewTitle";
import { defineComponent, ref } from "@vue/composition-api";
import { module as PassportArchiveStoreReportView } from "@monorepo/reports/src/store/modules/passportArchiveStoreReportView";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import { mapActions, mapGetters, Module } from "vuex";
import { breadcrumbs } from "@monorepo/reports/src/views/PassportArchiveStoreReportView/constants/breadcrumbs";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import ExportButton from "@monorepo/uikit/src/components/tableViews/ExportButton.vue";
import useSetStartFilters from "@monorepo/utils/src/composables/useSetStartFilters";
import useInitEducationPanel from "@monorepo/utils/src/composables/useInitEducationPanel";
import useSetStartCells from "@monorepo/utils/src/composables/useSetStartCells";
import useOpenHistoryMode from "@monorepo/utils/src/composables/useOpenHistoryMode";
import { fields } from "@monorepo/utils/src/variables/projectsData/passportArchiveStoreReport/fields";
import { v4 as uuid } from "uuid";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/passportArchiveStoreReport/filtersElements";
import { currentFilters as setCurrentFilters } from "@monorepo/reports/src/views/PassportArchiveStoreReportView/utils/defaultCurrentFilters";
import useAsyncExport from "@monorepo/utils/src/composables/useAsyncExport";

const SECTION_NAME = "passportArchiveStoreReportView";

export default defineComponent({
  name: "PassportArchiveStoreReportView",
  components: {
    TableSettings,
    ExportButton,
    Table,
    Filters,
    ScrollPanel,
    TableHeader,
    Breadcrumbs,
  },
  data() {
    return {
      breadcrumbs,
      viewTitle,
      viewUniqKey,
      educationIds,
      filtersElements,
      setCurrentFilters,
      fields,
      autocompleteFiltersKeys: [fields.FILTER_ARCHIVE],
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters(SECTION_NAME, ["cells", "openedId", "infiniteId", "isOpenFilters", "data", "totalLength", "isTableLoading"]),
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.REPORT_EXPORT);
    },
    headers(): {
      text: string;
      tooltip: string;
      defaultWidth: number;
      value: string;
      isSorted: boolean;
      isHandle: boolean;
      id: string;
    }[] {
      return [
        {
          text: "№ п/п",
          tooltip: "№ п/п",
          defaultWidth: 100,
          value: fields.TABLE_COLUMN_NUMBER,
          isSorted: true,
          isHandle: false,
          id: uuid(),
        },
        {
          text: "Группа документов, о количестве которых требуются cведения",
          tooltip: "Группа документов, о количестве которых требуются cведения",
          defaultWidth: 500,
          value: fields.TABLE_COLUMN_GROUP,
          isSorted: true,
          isHandle: false,
          id: uuid(),
        },
        {
          text: "Всего",
          tooltip: "Всего",
          defaultWidth: 340,
          value: fields.TABLE_COLUMN_TOTAL,
          isSorted: true,
          isHandle: false,
          id: uuid(),
        },
      ];
    },
  },
  methods: {
    ...mapActions(SECTION_NAME, ["getEventList", "getExportData", "addOpenedId", "setIsOpenFilters"]),
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setCells(value: { value: string }[]) {},
  },
  setup(props, { root }) {
    const filtersLoaded = ref(false);
    const sectionName = ref(SECTION_NAME); // sectionName === store module name

    useInitTableStoreModule(root, SECTION_NAME, PassportArchiveStoreReportView as Module<unknown, unknown>);
    useOpenHistoryMode(root, SECTION_NAME);
    useSetStartCells(root, SECTION_NAME, root.$store.getters[`${SECTION_NAME}/section`], []);
    useSetStartFilters(root, SECTION_NAME, ["id"]).then(() => {
      useInitEducationPanel(root);
      filtersLoaded.value = true;
    });
    useAsyncExport(root, SECTION_NAME);

    return {
      filtersLoaded,
      sectionName,
    };
  },
});
